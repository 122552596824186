import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Biographie from '../components/Biographie'

import pic_1 from '../assets/images/pic_animal_11.jpg'
import pic_2 from '../assets/images/pic_immo_1.jpg'
import pic_3 from '../assets/images/pic_scolaire_2.jpg'
import pic_scolaire_6 from '../assets/images/pic_scolaire_6.jpg'
import pic_4 from '../assets/images/pic_food_1.jpg'
import pic_5 from '../assets/images/pic_mariage_1.jpg'
import pic_6 from '../assets/images/pic_evenement_2.jpg'
import pic_7 from '../assets/images/pic_galerie_1.jpg'
import pic_8 from '../assets/images/pic_galerie_18.jpg'


class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Vincent DUCOS photographe à Poitiers"
                    meta={[
                        { name: 'description', content: 'Vincent DUCOS photographe à Poitiers' },
                        { name: 'keywords', content: 'photographe, 86, photo,photos, Poitiers, Vienne, Poitou-Charentes, animal, animaux, animalier, scolaire, ecole, immobilier, mariage, mariages' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="prestations" className="tiles">
                        <article style={{backgroundImage: `url(${pic_7})`}}>
                            <header className="major">
                            <h3>Portfolio</h3>
                                <p>Réalisations personnelles</p>
                            </header>
                            <Link to="/galerie" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_5})`}}>
                            <header className="major">
                                <h3>Mariages</h3>
                                <p>Mairie, Église, Vin d'honneur, Groupe, Soirée</p>
                            </header>
                            <Link to="/mariage" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_2})`}}>
                            <header className="major">
                                <h3>Immobilier</h3>
                                <p>Maison, Appartement, Immeuble</p>
                            </header>
                            <Link to="/immobilier" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_4})`}}>
                            <header className="major">
                                <h3>Culinaires</h3>
                                <p>Restaurant, Plat, Reportage</p>
                            </header>
                            <Link to="/culinaire" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_3})`}}>
                            <header className="major">
                                <h3>Scolaires</h3>
                                <p>Maternelles primaires collèges lycées</p>
                            </header>
                            <Link to="/scolaire" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_1})`}}>
                            <header className="major">
                                <h3>Animaux</h3>
                                <p>Plumes, Poils, Écailles</p>
                            </header>
                            <Link to="/animaux" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic_6})`}}>
                            <header className="major">
                                <h3>Evénementiel</h3>
                                <p>Concert, Spectacle, Reportage</p>
                            </header>
                            <Link to="/evenementiel" className="link primary"></Link>
                        </article>
                    </section>

                    <Biographie />
                </div>

            </Layout>
        )
    }
}

export default HomeIndex