import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Vincent DUCOS</h1>
                <h2>Photographe professionnel</h2>
            </header>
            <div className="content align-right">
                <h3><i>"Photographier, c'est une attitude, une façon d'être, une manière de vivre"<br/>Henri Cartier-Bresson</i></h3>
                <ul className="actions vertical align-left">
                    <li><a href="#prestations" className="button scrolly small special">En savoir plus</a></li>
                    <li><a href="#biographie" className="button scrolly small special">Qui suis-je ?</a></li>
                    {/*<li><a href="http://boutique.vincentducos.fr" target='blank' className="button scrolly small special">Boutique tirages d'art</a></li>*/}
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
